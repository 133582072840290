.hlit-ftr{
	padding:20px;
	border:1px solid #ccc;
}
.hlit-ftr-inp{
    position: relative;
}
.hlit-ftr-badge{
    position: absolute;
    right:-5px;
    top:-9px;
    background-color: #DC392D;
    color: #fff;
    height:21px;
    font-size: 0.9rem;
    text-align: center;
    line-height: 1;
    font-weight: 500;
    padding: 3px 10px 0 10px;
    display: none;
}
.hlit-ftr-badge-on{
    display: block;
}
.hlit-inp{
	width: 100%;
	border:1px solid #ccc;
	outline: 0;
	padding: 5px 10px;
    font-weight: 600;
    font-size: 1rem;
    color: #a4a4a4;
}