.skl-div-lft{
    float: left;
    width: 48%;
    padding-right:10px;
    border-right:1px solid #ccc;
}
.skl-div-rgt{
    float: right;
    width: 52%;
    padding-left:15px;
}