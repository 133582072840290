.hm-cont{
	background: #F2F6FC;
	overflow: hidden;
}
.hm-bn{
	background:#DC392D;
	height:180px;
	position:relative;
}
.hm-bn-pol{
	position:absolute;
	top: 160px;
    left: 0px;
    border-color: transparent transparent transparent transparent;
    border-style: solid;
    border-width: 40px 865px 40px 865px;
    height: 0px;
    width: 0px;
	border-color: #DC392D transparent transparent #DC392D;
	z-index:200;
}
.hm-bn-mid{
	margin:0 auto;
	max-width:1130px;
	padding:30px 10px;
	position:relative;
}
.hm-bn-hd{
	color:#fff;
	font-size: 2rem;
}
.hm-bn-subhd{
	font-size: 1.15rem;
	color: #ffffff90;
	max-width:900px;
	max-height:110px;
	overflow-y: auto;
	display: inline;
}
.hm-bn-subhd-lg{
	display: none;
}
.hm-bn-dbtn{
	position:absolute;
	top:0px;
	left:0px;
	z-index:300;
}
.hm-bn-bx-btn{
	margin-top:20px;
	position:relative;
}
.hm-bn-btn{
	font-size:15px;
	font-weight:bold;
	padding:10px 24px;
	display:inline-block;
	cursor:pointer;
	color:#fff;
	background:#BE1B0F;
	text-decoration:none;
	border: 0;
}
.hm-bn-btn:hover{
	text-decoration:none;
	color:#fff;
	background:#aa0700;
}
.hm-sk{
	margin-top:100px;
	padding:0 20px 60px 20px;
}
.hm-sk-hd{
	padding-bottom:15px;
	border-bottom:1px solid #dedede;
}
.hm-sk-rgt{
	float:right;
	box-shadow: 0 0.15rem 1.75rem #21283226;
}
.hm-sk-rgt-elm{
	border:1px solid #dedede;
	padding:7px 20px;
	cursor:pointer;
	border-top:0;
}
.hm-sk-rgt-elm:first-child{
	border-left:1px solid #dedede;
}
.hm-sk-rgt-elm:hover .hm-sk-off{
	color:#444;
}
.hm-sk-rgt-elm-first{
	border-top:1px solid #dedede;
}
.hm-sk-rgt-lg{
	display: none;
}
.hm-sk-rgt-sm{
	position: relative;
	width:140px;
}
.hm-sk-rgt-sm-mr{
	position: absolute;
	width:100%;
	top: 100%;
    left: 0px;
	z-index:100;
	background-color: #F2F6FC;
}
.hm-sk-on{
	color:#DC392D;
}
.hm-sk-off{
	color:#ccc;
}
.hm-sk-img{
	border:0px dashed #444;
}
.hm-sk-lst{
	text-align: center;
    padding: 20px 0 0 0;
}
.hm-sk-lst-elm{
	display: inline-block;
	width: 250px;
    height: 230px;
	padding: 1px;
}
.hm-sk-lst-elm-hd{
	height:80%;
	/*box-shadow: 0 0.15rem 1.75rem #21283226;*/
	padding:30px 10px;
}
.hm-sk-lst-elm-img{
	height:100%;
    display: flex;
    align-items: center;
    justify-content: center;
}
.hm-sk-lst-elm-ft{
	padding:10px;
	height:20%;
	font-weight:600;
	font-size:0.9rem;
	border: 1px solid #aeaeae;
}
.hm-sk-lst-elm-ft-rt{
	color:#a2a2a2;
}
.hm-comp{
	background:#fff;
	padding:60px 0 200px 0;
}
.hm-comp-h{
	text-align:center;
}
.hm-comp-lst{
	text-align: center;
    padding: 20px 0 0 0;
    margin: 50px 0 0 0;
}
.hm-comp-lst-elm{
	display: inline-block;
	width: 200px;
    height: 130px;
	padding: 1px;
}
.hm-comp-lst-elm-hd{
	background:#fff;
	height:80%;
	padding:30px 10px;
	border:1px solid #dedede;
	display: flex;
    align-items: center;
    justify-content: center;
}
.hm-comp-lst-elm-ft{
	padding:5px 5px 0 5px;
	height:20%;
	font-weight:600;
	font-size:0.9rem;
	text-align:right;
	color:#a2a2a2;
}
.hm-em{
	position:relative;
}
.hm-em-bx{
	position:absolute;
	top:-200px;
	left:0;
	width:100%;
	border:1px solid #dedede;
	z-index:200;
	padding:30px 40px;
	background:#fff;
	text-align:center;
	box-shadow: 0 0.15rem 1.75rem #21283226;
	max-height: 350px;
	overflow-y: auto;
}
.hm-em-tl{
	color:#69707a;
}
.hm-em-subtl{
	margin-top:13px;
	color:#707070;
	font-weight: 400;
}
.hm-em-btn{
	font-size:15px;
	font-weight:bold;
	padding:10px 24px;
	display:inline-block;
	cursor:pointer;
	color:#fff;
	background:#DC392D;
	text-decoration:none;
	border: 0;
	outline: none;
}
.hm-em-btn:hover{
	text-decoration:none;
	color:#fff;
	background:#BB3026;
	border: 0;
	outline: none;
}
.hm-ft{
	border-top:0px solid #ccc;
	padding:10px 0;
	font-size:0.70rem;	
    font-weight: 600;
	color:#fff;
}
@media(min-width: 650px){  	
	.hm-bn-hd{
		font-size: 2.5rem;
	}
	.hm-bn{
		height:180px;
	}
	.hm-bn-pol{
		top: 180px;
	}	
	.hm-sk-rgt-lg{
		display: block;
	}
	.hm-sk-rgt-sm{
		display: none;
	}
	.hm-sk-rgt-elm{
		float:left;		
		border-top-width:1px;
		border-left:0;
	}
}
@media(min-width: 850px){  	
	.hm-bn-hd{
		font-size: 3rem;
	}
	.hm-bn-subhd-lg{
		display: inline;
	}
}