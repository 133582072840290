
.hist-cont {    
    display: block;
    position: relative;
    margin: 0px 0 80px 0;
}
.hist-lft {
    width: 100%;
    padding: 150px 0px 0px 0;
    position: relative;
}
.hist-lft-first {
    width: 100%;
}
.hist-mid {
    background-color: #ccc;
    width: 100%;
    position: relative;
    display: none;
}
.hist-rgt {
    width: 100%;
    padding: 0px 0 0 0px;
}
.hist-bx {    
    position: relative;
    width:100%;
    background-color: #fff;
    border: 1px solid #ccc;
    color:#444;
    box-shadow: 0 0.15rem 1.75rem #21283226;
}
.hist-shadow{
    box-shadow: 0 0.15rem 1.75rem #21283226;
    margin-bottom:0;
}
.hist-dot{
    position: absolute;
    background-color: #fff;
    border: 1px solid #ccc;
    height:50px;
    width:50px;
    top:50px;
    left:-22px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #444;
    font-size: 1.3rem;
    z-index: 100;
}
.hist-dot-first{
    top:83px;
}
.hist-dot-sm{
    position: absolute;
    top: -75px;
    right: 0;
    background-color: #fff;
    height:50px;
    width:100%;
    z-index: 100;
}
.hist-dot-bx-sm{
    position: relative;
    height: 100%;
    width:50px;
    margin:0 auto;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #444;
    border: 1px solid #ccc;  
    font-size: 1.3rem;
}
.hist-arr{
    position: absolute;
    background-color: #ccc;
    height: 40px;
    width: 4px;
    top: -40px;
    right: 50%;
}
.hist-tm{
    width:100%;
    height: 100%;
    background-color: transparent;
    color:#444;
    font-weight: 500;
    font-size: 1.1rem;
    margin-top: 32px;
    display: none;
}
.hist-tm-first {
    padding-top: 30px;
}
.hist-tm-sm{
    position: absolute;
    top: -22px;
    left: 60px;
    width: 115px;
    height: 30px;
    background-color: transparent;
    color:#444;
    font-weight: 500;
    font-size: 1.1rem;
    margin-top: 32px;
    z-index: 200;
    display: none;
}
.hist-tmr-sm{
    left: -125px;
    width: 115px;
    height: 30px;
    text-align: right;
}
.hist-hide{
    background-color: transparent;
    border: 0;
    box-shadow: none;
}
.hist-txt{
    height: 100%;
    padding:20px;
}
.hist-txt-tl{
    color:#444;
    font-size: 1.2rem;
    font-weight: 500;    
}
.hist-txt-subtl{
    margin-top: 2px;
    color:#DC392D;
    font-size: 1.2rem;
    font-weight: 500;    
}
.hist-txt-dsc{
    margin-top: 5px;
    color:#444;
    font-size: 0.95rem
}
.hist-txt-loc{
    margin-top: 10px;
    color:#aeaeae;
    font-size: 1rem;
    text-align: right;
    font-weight: 500;
}
.hist-txt-locl{
    text-align: left;
}
@media(min-width: 300px){
    .hist-tm-sm{
        display: block;
    }
}

@media(min-width: 550px){
    .hist-lft {
        position: unset;
    }    
    .hist-lft {
        padding: 30px 50px 0px 0;
    }
    .hist-rgt {
        padding: 30px 0 0 50px;
    }
    .hist-lft-first {
        padding: 62px 50px 0px 0;
    }
    .hist-mid {
        display: block;
    }
    .hist-tm{
        display: block;
    }
    .hist-arrr{    
        left:-40px;
    }
    .hist-dot-sm{
        display: none;
    }
    .hist-cont {    
        display: grid;
        grid-template-columns: 49.77% 0.45% 49.77%;
    }
    .hist-arr{
        height:4px;
        width:40px;
        top:42px;
        right:-40px;
    }
}