.main-bd{
	margin:0 auto;
	max-width:1110px;
	padding:0 5px;
}

/* gen */
.dispnone{display:none;}.dispblk{display:block;}.dispflx{display:flex;}
.inp-err{border:1px solid #a94442!important;}
.fntz10{ font-size:10px; }.fntz11{ font-size:11px; }.fntz12{ font-size:12px; }.fntz13{ font-size:13px; }.fntz14{ font-size:14px; }.fntz15{ font-size:15px; }.fntz16{ font-size:16px; }
.pd0{padding:0;}.pd20{padding:20px;}
.pd-t0{padding-top:0px;}.pd-t1{padding-top:1px;}.pd-t2{padding-top:2px;}.pd-t3{padding-top:3px;}.pd-t4{padding-top:4px;}.pd-t5{padding-top:5px;}.pd-t6{padding-top:6px;}.pd-t7{padding-top:7px;}.pd-t8{padding-top:8px;}.pd-t9{padding-top:9px;}.pd-t10{padding-top:10px;}.pd-t11{padding-top:11px;}.pd-t12{padding-top:12px;}.pd-t13{padding-top:13px;}.pd-t14{padding-top:14px;}.pd-t15{padding-top:15px;}.pd-t16{padding-top:16px;}.pd-t17{padding-top:17px;}.pd-t18{padding-top:18px;}.pd-t19{padding-top:19px;}.pd-t20{padding-top:20px;}.pd-t21{padding-top:21px;}.pd-t22{padding-top:22px;}.pd-t23{padding-top:23px;}.pd-t24{padding-top:24px;}.pd-t25{padding-top:25px;}.pd-t26{padding-top:26px;}.pd-t27{padding-top:27px;}.pd-t28{padding-top:28px;}.pd-t29{padding-top:29px;}.pd-t30{padding-top:30px;}.pd-t40{padding-top:40px;}.pd-t50{padding-top:50px;}.pd-t60{padding-top:60px;}.pd-t70{padding-top:70px;}.pd-t80{padding-top:80px;}
.pd-r0{padding-right:0px;}.pd-r1{padding-right:1px;}.pd-r2{padding-right:2px;}.pd-r3{padding-right:3px;}.pd-r4{padding-right:4px;}.pd-r5{padding-right:5px;}.pd-r6{padding-right:6px;}.pd-r7{padding-right:7px;}.pd-r8{padding-right:8px;}.pd-r9{padding-right:9px;}.pd-r10{padding-right:10px;}.pd-r11{padding-right:11px;}.pd-r12{padding-right:12px;}.pd-r13{padding-right:13px;}.pd-r14{padding-right:14px;}.pd-r15{padding-right:15px;}.pd-r16{padding-right:16px;}.pd-r17{padding-right:17px;}.pd-r18{padding-right:18px;}.pd-r19{padding-right:19px;}.pd-r20{padding-right:20px;}.pd-r21{padding-right:21px;}.pd-r22{padding-right:22px;}.pd-r23{padding-right:23px;}.pd-r24{padding-right:24px;}.pd-r25{padding-right:25px;}.pd-r26{padding-right:26px;}.pd-r27{padding-right:27px;}.pd-r28{padding-right:28px;}.pd-r29{padding-right:29px;}.pd-r30{padding-right:30px;}.pd-r40{padding-right:40px;}.pd-r50{padding-right:50px;}.pd-r60{padding-right:60px;}.pd-r70{padding-right:70px;}.pd-r80{padding-right:80px;}
.pd-b0{padding-bottom:0px;}.pd-b1{padding-bottom:1px;}.pd-b2{padding-bottom:2px;}.pd-b3{padding-bottom:3px;}.pd-b4{padding-bottom:4px;}.pd-b5{padding-bottom:5px;}.pd-b6{padding-bottom:6px;}.pd-b7{padding-bottom:7px;}.pd-b8{padding-bottom:8px;}.pd-b9{padding-bottom:9px;}.pd-b10{padding-bottom:10px;}.pd-b11{padding-bottom:11px;}.pd-b12{padding-bottom:12px;}.pd-b13{padding-bottom:13px;}.pd-b14{padding-bottom:14px;}.pd-b15{padding-bottom:15px;}.pd-b16{padding-bottom:16px;}.pd-b17{padding-bottom:17px;}.pd-b18{padding-bottom:18px;}.pd-b19{padding-bottom:19px;}.pd-b20{padding-bottom:20px;}.pd-b21{padding-bottom:21px;}.pd-b22{padding-bottom:22px;}.pd-b23{padding-bottom:23px;}.pd-b24{padding-bottom:24px;}.pd-b25{padding-bottom:25px;}.pd-b26{padding-bottom:26px;}.pd-b27{padding-bottom:27px;}.pd-b28{padding-bottom:28px;}.pd-b29{padding-bottom:29px;}.pd-b30{padding-bottom:30px;}.pd-b40{padding-bottom:40px;}.pd-b50{padding-bottom:50px;}.pd-b60{padding-bottom:60px;}.pd-b70{padding-bottom:70px;}.pd-b80{padding-bottom:80px;}
textarea:focus, input:focus, select:focus{ outline: none; }
.cent-mid {
    display: flex;
    align-items: center;
    justify-content: center;
}
.imgmax{
    width: auto;
    height: auto;
    max-width: 100%;
    max-height: 100%;
}
img.mid-max {
  max-height: 100%;
  max-width: 100%;
  width: auto;
  height: auto;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  margin: auto;
}
img.imgmid{
    display: block;
    margin-left: auto;
    margin-right: auto;
}
a{ text-decoration:none; }
a:hover{ text-decoration:underline; }
.clr{clear:both;}.fltl{float:left;}.fltr{float:right;}
.txt-dots{
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.txt-cent{ text-align: center; }.txt-rgt{ text-align: right; }
.crsr-p{cursor:pointer;}.crsr-no{cursor: default;}
.lk-44{color:#444;}
.lk-inh{ color:inherit; }
.lk-inh:hover{ color:inherit; }
.lk-065{ color:#0654ba; }
.lk-065:hover{ color:#0654ba; }
.lk-212 {color: #212529;}
.lk-212:hover{color: #212529;}
.lk-blk212{
	color:#212529;
}
.lk-blk212:hover{
	color:#212529;
	text-decoration:underline;
}
.clr-007{ color:#0076C1; }
.clr-ccc{ color:#cccccc; }
.clr-a94{ color:#a94442; }
.bod-all{
	position:relative;
	height:100vh;
}
.no-ovrflw{ overflow:hidden; }
.hgt100{height:100%;}
.h0{ padding:0;margin:0; }
.txt-err{ color: #a94442;font-size: 14px; }
.clr-err{ color: #a94442!important; }
.bx-shadow-grey{
	-webkit-box-shadow: 0px 0px 5px 0px rgba(174,174,174,0.65);
	-moz-box-shadow: 0px 0px 5px 0px rgba(174,174,174,0.65);
	box-shadow: 0px 0px 5px 0px rgba(174,174,174,0.65);
}
.bold-txt{font-weight:700 !important;}
.pos-abs{position:absolute;}.pos-rel{position:relative;}
.top-m1{top:-1px;}.top-m2{top:-2px;}.top-m3{top:-3px;}.top-m4{top:-4px;}.top-m5{top:-5px;}.top-m6{top:-6px;}
.top-p1{top:1px;}.top-p2{top:2px;}.top-p3{top:3px;}.top-p4{top:4px;}.top-p5{top:5px;}.top-p6{top:6px;}
.notif-danger{color:#a94442;}
.notif-success{color:green;}
.gen-bd{
	background-color: #fff;
}
.gen-mid{
	margin: 0 auto;
    max-width: 1130px;
    padding: 0 10px;
}
.gen-bx{
    border: 1px solid #dee2e6;
    background-color: #fff;
    padding: 10px;
}
.gen-sb-hd{
    background:#DC392D;
    padding: 10px 0 13px 0;
}
.gen-sb-tl{
    color:#fff;
}
.arr-mn{
    color:#aeaeae;
}
.arr-mn:hover{
    color:#212529;
}
.rsum-lft{
	float:left;
	width:100%;
	background-color: #fff;	
}
.rsum-rgt{
	float:left;
	width:300px;
	margin-left:20px;
    display: none;
}
.rsum-badge{
    background-color: #DC392D;
    color:#fff;
    padding:1px 5px;
}
.rsum-desc{
	font-size: 0.90rem;
	padding:5px 0;
}
.rsum-title{
	color: #444;
	background-color: #ccc;
	padding:4px 15px 6px 15px;
	font-weight: 500;
	margin-top:0px;
	font-size: 1.3rem;
    border: 2px solid #444;
}
.rsum-exp-nm{
	color: #444;
    font-size: 1.2rem;
    font-weight: 500;
    padding:10px 0px 0 0;
}
.rsum-exp{
	margin:0px 0 0 15px;
	border-left: 1px solid #ccc;
    padding: 0 0px 40px 15px;
    position: relative;
}
.rsum-exp-top{
	margin-top: 20px;
}
.rsum-bullet{
	position: absolute;
    font-size: 0.65rem;
    left: -5px;
    top: 17px;
    color: #444;
}
.rsum-role {
    float: left;
    font-size: 0.95rem;
    font-weight: 500;
    width:50%;
}
.rsum-company {
    float: right;
    font-size: 0.95rem;
    font-weight: 500;
    padding-right: 10px;
    color: #444;
    width:50%;
    text-align: right;
}
.rsum-time {
    font-size: 0.95rem;
    font-weight: 400;
}
.rsum-task {
    font-size: 0.95rem;
}
.rsum-list {
    margin: 0px 0 1px 0;
    padding-left: 25px;
    list-style: square;
}
.rsum-tech {
	margin-top: 5px;
    color: #444;
    font-size: 0.95rem;
    font-weight: 400;
}
.rsum-tag {
	display: inline-block;
	padding:2px 8px;
	border: 1px dashed #444;
	margin:3px 10px 10px 0;
	font-weight: 400;
}
.rsum-sub-lab{
    font-size: 0.90rem;
    font-weight: 500;
    color: #707070;
    margin-top: 5px;
}
.rsum-src-bx{
    margin:15px 0 10px 0;
    border:1px solid #ccc;
    padding:13px 15px;
    display: block;
}
.rsum-bot-btn{
    width:300px;
    margin-top: 30px;
    float: right;
}
@media(min-width: 850px){ 
    .rsum-src-bx{
        display: none;
    }
    .rsum-bot-btn{
        display: none;
    }
    .rsum-lft{
        float:left;
        width:calc(100% - 320px);
    }
    .rsum-rgt{
        display: block;
    }
}
.pd-l0{padding-left:0px;}.pd-l1{padding-left:1px;}.pd-l2{padding-left:2px;}.pd-l3{padding-left:3px;}.pd-l4{padding-left:4px;}.pd-l5{padding-left:5px;}.pd-l6{padding-left:6px;}.pd-l7{padding-left:7px;}.pd-l8{padding-left:8px;}.pd-l9{padding-left:9px;}.pd-l10{padding-left:10px;}.pd-l11{padding-left:11px;}.pd-l12{padding-left:12px;}.pd-l13{padding-left:13px;}.pd-l14{padding-left:14px;}.pd-l15{padding-left:15px;}.pd-l16{padding-left:16px;}.pd-l17{padding-left:17px;}.pd-l18{padding-left:18px;}.pd-l19{padding-left:19px;}.pd-l20{padding-left:20px;}.pd-l21{padding-left:21px;}.pd-l22{padding-left:22px;}.pd-l23{padding-left:23px;}.pd-l24{padding-left:24px;}.pd-l25{padding-left:25px;}.pd-l26{padding-left:26px;}.pd-l27{padding-left:27px;}.pd-l28{padding-left:28px;}.pd-l29{padding-left:29px;}.pd-l30{padding-left:30px;}.pd-l40{padding-left:40px;}.pd-l50{padding-left:50px;}.pd-l60{padding-left:60px;}.pd-l70{padding-left:70px;}.pd-l80{padding-left:80px;}
.mg0{margin:0;}
.mg-t0{margin-top:0px;}.mg-t1{margin-top:1px;}.mg-t2{margin-top:2px;}.mg-t3{margin-top:3px;}.mg-t4{margin-top:4px;}.mg-t5{margin-top:5px;}.mg-t6{margin-top:6px;}.mg-t7{margin-top:7px;}.mg-t8{margin-top:8px;}.mg-t9{margin-top:9px;}.mg-t10{margin-top:10px;}.mg-t11{margin-top:11px;}.mg-t12{margin-top:12px;}.mg-t13{margin-top:13px;}.mg-t14{margin-top:14px;}.mg-t15{margin-top:15px;}.mg-t16{margin-top:16px;}.mg-t17{margin-top:17px;}.mg-t18{margin-top:18px;}.mg-t19{margin-top:19px;}.mg-t20{margin-top:20px;}.mg-t21{margin-top:21px;}.mg-t22{margin-top:22px;}.mg-t23{margin-top:23px;}.mg-t24{margin-top:24px;}.mg-t25{margin-top:25px;}.mg-t26{margin-top:26px;}.mg-t27{margin-top:27px;}.mg-t28{margin-top:28px;}.mg-t29{margin-top:29px;}.mg-t30{margin-top:30px;}.mg-t40{margin-top:40px;}.mg-t50{margin-top:50px;}.mg-t60{margin-top:60px;}.mg-t70{margin-top:70px;}.mg-t80{margin-top:80px;}
.mg-r0{margin-right:0px;}.mg-r1{margin-right:1px;}.mg-r2{margin-right:2px;}.mg-r3{margin-right:3px;}.mg-r4{margin-right:4px;}.mg-r5{margin-right:5px;}.mg-r6{margin-right:6px;}.mg-r7{margin-right:7px;}.mg-r8{margin-right:8px;}.mg-r9{margin-right:9px;}.mg-r10{margin-right:10px;}.mg-r11{margin-right:11px;}.mg-r12{margin-right:12px;}.mg-r13{margin-right:13px;}.mg-r14{margin-right:14px;}.mg-r15{margin-right:15px;}.mg-r16{margin-right:16px;}.mg-r17{margin-right:17px;}.mg-r18{margin-right:18px;}.mg-r19{margin-right:19px;}.mg-r20{margin-right:20px;}.mg-r21{margin-right:21px;}.mg-r22{margin-right:22px;}.mg-r23{margin-right:23px;}.mg-r24{margin-right:24px;}.mg-r25{margin-right:25px;}.mg-r26{margin-right:26px;}.mg-r27{margin-right:27px;}.mg-r28{margin-right:28px;}.mg-r29{margin-right:29px;}.mg-r30{margin-right:30px;}.mg-r40{margin-right:40px;}.mg-r50{margin-right:50px;}.mg-r60{margin-right:60px;}.mg-r70{margin-right:70px;}.mg-r80{margin-right:80px;}
.mg-b0{margin-bottom:0px;}.mg-b1{margin-bottom:1px;}.mg-b2{margin-bottom:2px;}.mg-b3{margin-bottom:3px;}.mg-b4{margin-bottom:4px;}.mg-b5{margin-bottom:5px;}.mg-b6{margin-bottom:6px;}.mg-b7{margin-bottom:7px;}.mg-b8{margin-bottom:8px;}.mg-b9{margin-bottom:9px;}.mg-b10{margin-bottom:10px;}.mg-b11{margin-bottom:11px;}.mg-b12{margin-bottom:12px;}.mg-b13{margin-bottom:13px;}.mg-b14{margin-bottom:14px;}.mg-b15{margin-bottom:15px;}.mg-b16{margin-bottom:16px;}.mg-b17{margin-bottom:17px;}.mg-b18{margin-bottom:18px;}.mg-b19{margin-bottom:19px;}.mg-b20{margin-bottom:20px;}.mg-b21{margin-bottom:21px;}.mg-b22{margin-bottom:22px;}.mg-b23{margin-bottom:23px;}.mg-b24{margin-bottom:24px;}.mg-b25{margin-bottom:25px;}.mg-b26{margin-bottom:26px;}.mg-b27{margin-bottom:27px;}.mg-b28{margin-bottom:28px;}.mg-b29{margin-bottom:29px;}.mg-b30{margin-bottom:30px;}.mg-b40{margin-bottom:40px;}.mg-b50{margin-bottom:50px;}.mg-b60{margin-bottom:60px;}.mg-b70{margin-bottom:70px;}.mg-b80{margin-bottom:80px;}
.mg-l0{margin-left:0px;}.mg-l1{margin-left:1px;}.mg-l2{margin-left:2px;}.mg-l3{margin-left:3px;}.mg-l4{margin-left:4px;}.mg-l5{margin-left:5px;}.mg-l6{margin-left:6px;}.mg-l7{margin-left:7px;}.mg-l8{margin-left:8px;}.mg-l9{margin-left:9px;}.mg-l10{margin-left:10px;}.mg-l11{margin-left:11px;}.mg-l12{margin-left:12px;}.mg-l13{margin-left:13px;}.mg-l14{margin-left:14px;}.mg-l15{margin-left:15px;}.mg-l16{margin-left:16px;}.mg-l17{margin-left:17px;}.mg-l18{margin-left:18px;}.mg-l19{margin-left:19px;}.mg-l20{margin-left:20px;}.mg-l21{margin-left:21px;}.mg-l22{margin-left:22px;}.mg-l23{margin-left:23px;}.mg-l24{margin-left:24px;}.mg-l25{margin-left:25px;}.mg-l26{margin-left:26px;}.mg-l27{margin-left:27px;}.mg-l28{margin-left:28px;}.mg-l29{margin-left:29px;}.mg-l30{margin-left:30px;}.mg-l40{margin-left:40px;}.mg-l50{margin-left:50px;}.mg-l60{margin-left:60px;}.mg-l70{margin-left:70px;}.mg-l80{margin-left:80px;}
.bd-0{border:0;}.bd-t0{border-top:0;}.bd-r0{border-right:0;}.bd-b0{border-bottom:0;}.bd-l0{border-left:0;}
.bd-0:hover{border:0;}.bd-t0:hover{border-top:0;}.bd-r0:hover{border-right:0;}.bd-b0:hover{border-bottom:0;}.bd-l0:hover{border-left:0;}
.bd-t1{border-top-width:1px;}.bd-r1{border-right-width:1px;}.bd-b1{border-bottom-width:1px;}.bd-l1{border-left-width:1px;}
.bd-rd0{border-radius:0;}
.clr-red{ background-color:#E7222C; }
.clr-blu{ background-color:#4173CD; }
.bg-red{ background-color:#E7222C; }
.bg-blu{ background-color:#4173CD; }