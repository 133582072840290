.ft-bot{
	border-top:0px solid #ccc;
	padding:10px 0 7px 0;
	font-size:0.75rem;	
    font-weight: 600;
	color:#fff;
}
.ft-lg{
	background:#444;
	margin-top: 100px;
}
.ft-lft{
	display: none;
}
.ft-rgt{
	float: right;
}
@media(min-width: 400px){  
	.ft-lg{
		margin-top: 60px;
	}
	.ft-lft{
		display: block;
		float: left;
	}
}
@media(min-width: 600px){  
	.ft-lg{
		margin-top: 0px;
	}
}