.hd-tp-mid{	
	background:#fff;
	height:34px;
	padding:0 10px;
}
.hd-tp{
	margin: 0 auto;
	max-width: 1110px;
	height:100%;
	padding:3px 0px 0 0px;
	font-size:0.9rem;	
	border-bottom:0px solid #dedede;
}
.hd-tp-lft{
	float:left;
	height:100%;
}
.hd-tp-rgt{
	float:right;
	height:100%;
}
.ht-tp-rgt-lg{
	height: 100%;
	display:none;
}
.ht-tp-rgt-sm{
	display:block;
	font-size:20px;
}
.hd-tp-sep{
	width:15px;
	height:19px;
	margin-left:15px;
	border-left:1px solid #ccc;
	margin-top:3px;
	float:left;
}
.hd-tp-icn{
	padding-top:0px;
	color:#444;	
	cursor:pointer;
	width:13px;
}
.hd-tp-on{
	line-height: 1;
	font-size:0.8rem;
}
.hd-tp-sts{
	margin: 3px 0 0 0px;
    border: 1px solid #aeaeae;
    height: 22px;
    width: 70px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #707070;
    font-weight: 500;
}
.hd-tp-txt{
	height:100%;
	float:left;
	font-weight:500;
	padding-top:2px;
	cursor:pointer;
}
.hd-tp-txt:hover{
	border-bottom:2px solid #444;
}
.hd-tp-txt-on{
	border-bottom:2px solid #444;
}
.hd-tp-lg{
	height:100%;
	float:left;
	cursor:pointer;
	position: relative;
}
.hd-tp-lg-mr{
	position: absolute;
	top:100%;
	left:-10px;
	width:75px;
	max-height:250px;
	overflow-y: auto;
	background:#fff;
	z-index:100;
	border:1px solid #aeaeae;
}
.hd-tp-lg-mr-elm{
	padding:5px 10px;
	cursor:pointer;
}
.hd-tp-lg-mr-elm:hover{
	background-color:#f2f2f2;
}
.hd-tp-lg-sm{	
	font-size: 1rem;
}
.hd-tp-lg-mn{
	height:100%;
	float:left;
	font-size: 1.1rem;
	margin-right: 15px;
	position: relative;
}
.hd-tp-lg-mn-mr{
	position: absolute;
	top:25px;
	left:-105px;
	width:130px;
	max-height:250px;
	overflow-y: auto;
	background:#fff;
	z-index:100;
	border:1px solid #aeaeae;
	font-size: 0.95rem;
	padding:5px 0;
}
.hd-tp-lg-bar{
	margin-top:7px;
	width:20px;
	cursor:pointer;
}
.hd-tp-bar{
	background-color: #707070;
	height:3px;
	width:100%;
	margin-bottom: 2.5px;
}
.hd-tp-lg-bar:hover .hd-tp-bar{
	background-color: #444;
}
.hd-tp-lg-flg{
	height:100%;
	float:left;
	padding:0px 5px 0 0;
}
.hd-tp-lg-txt{
	height:100%;
	float:left;
	font-weight:500;
	padding:2px 5px 0 0;
}
.hd-tp-lg-arr{
	height:100%;
	float:left;
	padding:1px 0 0 0;
}
.hd-bd{
	background:#444;
	height: 70px;
    padding: 5px 10px 0 10px;
	border-top: 0px solid #ccc;
	border-bottom: 0px solid #ccc;
}
.hd-bd-mid{
	margin:0 auto;
	max-width:1110px;
	height:100%;
	position:relative;
}
.hd-bd-lg{
	float:left;
	height:60px;
	width:70px;
	cursor: pointer;
}
.hd-bd-txt{
	float:left;
	height:60px;
	width:110px;
    align-items: center;
    justify-content: center;
	font-weight:600;
	font-size:1.3rem;
	display: none;
	cursor: pointer;
	color:#fff;
}
.hd-bd-src{
	float:right;
	height:60px;
	width:calc(100% - 100px);
    display: flex;
    align-items: center;
    justify-content: center;
}
.hd-bd-src-mid{
	height:45px;
	width:100%;
	border: 0px solid #aeaeae;
    padding: 5px;
	background: #fff;
}
.hd-bd-src-txt{
	float:left;
	height:100%;
	width:100%;
}
.hd-bd-src-tp{
	position:relative;
	float:left;
	height:100%;
	width:25%;
	background:#fff;	
	cursor:pointer;
	display: none;
}
.hd-bd-src-icn{
	float:left;
	width:50px;
	height:100%;
    display: flex;
    align-items: center;
    justify-content: center;
	background:#444;
	color:#fff;
	font-size:1.1rem;
	cursor:pointer;
}
.hd-bd-src-icn:hover{
	background:#626262;
}
.hd-bd-src-inp{
	position:relative;
	float:left;
	width:calc(100% - 50px);
	height:100%;
	background:#fff;
}
.hd-bd-src-inp-tp{
	border:0;
	outline:0;
	padding:0;
	width:100%;
	height:100%;
	padding:5px 10px;
	background-color: #fff;
	font-weight:600;
	font-size:1rem;
	color:#909090;
}
.hd-bd-src-tp-sep{
	float:left;
	height:25px;
	width:1px;
	border-left:2px solid #ccc;
	margin-top:5px;
}
.hd-bd-src-tp-txt{
	float:left;
	width:calc(100% - 22px);
	height:100%;
	padding:0 10px;
    display: flex;
    align-items: center;
	font-weight:600;
	font-size:1.2rem;
	color:#909090;
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
}
.hd-bd-src-tp-arr{
	float:left;
	width:20px;
	height:100%;
    display: flex;
    align-items: center;
    justify-content: center;
}
.hd-bd-src-txt-auto, .hd-bd-src-tp-auto{
	position:absolute;
	top:39px;
	left:0;
	width:100%;
	max-height:250px;
	overflow-y: auto;
	background:#fff;
	z-index:100;
	border:1px solid #aeaeae;
	border-top:1px solid #fff;
	/*display:none;*/
}
.hd-bd-src-tp-auto{
	left:5px;
}
.hd-bd-src-txt-auto-elm{
	padding:5px 10px;
	font-size:1rem;
	cursor:pointer;
    color: #444;/*
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;*/
}
.hd-bd-src-txt-auto-elm:hover{
	background:#f2f2f2;
}
.hd-bd-src-sel-auto-elm{
	padding:5px 10px;
	font-size:1rem;
	cursor:pointer;
    color: #909090;
	font-weight: 600;
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
}
.hd-bd-src-sel-auto-elm:hover{
	background:#f2f2f2;
}
@media(min-width: 450px){    
	.hd-bd-src{
		width: calc(100% - 100px);
	}
	.hd-bd-src-txt{
		width:75%;
	}
	.hd-bd-src-tp{
		width:25%;
		display: block;
	}
	.hd-tp-lg-mr{
		left:-1px;
		width:65px;
	}
}
@media(min-width: 600px){    
	.ht-tp-rgt-sm{
		display:none;
	}
	.ht-tp-rgt-lg{
		display:block;
	}
	.hd-bd-txt{
		display:flex;
	}
	.hd-bd-src{
		width: calc(100% - 200px);
	}
}
@media(min-width: 650px){    
	
	.ht-tp-rgt-sm{
		display:none;
	}
}
@media(min-width: 700px){    	
	.hd-bd-src{
		width: calc(100% - 280px);
	}
	/*
	.hd-bd-src-txt{
		width:75%;
	}
	.hd-bd-src-tp{
		width:25%;
		display: block;
	}*/
}
@media(min-width: 1000px){    
	.hd-bd-src {
		width: calc(100% - 380px);
	}
}