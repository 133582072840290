.rsum-inp{
	width: 100%;
	border:1px solid #ccc;
	outline: 0;
	padding: 5px 10px;
    font-weight: 600;
    font-size: 1rem;
    color: #909090;
}
.rsum-ftr{
	padding:20px;
	border:1px solid #ccc;
}
.rsum-ftr-inp{
    position: relative;
}
.rsum-ftr-badge{
    position: absolute;
    right:-3px;
    top:-7px;
    background-color: #DC392D;
    color: #fff;
    width:21px;
    height:19px;
    font-size: 1rem;
    text-align: center;
    line-height: 1;
    font-weight: 500;
    padding-top: 1px;
    display: none;
}
.rsum-ftr-badge-on{
    display: block;
}
.rsum-txt{
	margin-top: 20px;
	color: #455a64;
    line-height: 20px;
	font-size: 1rem;
}
.rsum-btn{
	margin-top: 5px;
}
.rsum-btn-lft{
	float:left;
	width:48%;
}
.rsum-btn-rgt{
	float:right;
	width:48%;
}
.rsum-dwn-btn {
    font-size: 1rem;
    font-weight: bold;
    padding: 5px 24px;
    display: inline-block;
    cursor: pointer;
    color: #444;
    border:2px solid #444;
    background: #fff;
    text-decoration: none;
	width:100%;
    text-align: center;
}
.rsum-link-btn {
    font-size: 1rem;
    font-weight: bold;
    padding: 6px 24px 7px 24px;
    display: inline-block;
    cursor: pointer;
    color: #fff;
    background: #444;
    text-decoration: none;
    border: 0;
	width:100%;
}
.rsum-link-btn:hover {
    background: #303030;
}
.rsum-dwn-btn:hover {
    background: #f2f2f2;
    color:#444;
}
.rsum-sum{
	padding:20px;
	border:1px solid #ccc;
	margin-top: 20px;
}
.rsum-sum-bx{
    margin-top: 15px;
}
.rsum-sum-lft{
    float:left;
    font-size: 0.85rem;
}
.rsum-sum-rgt{
    float:right;
    font-size: 0.85rem;
}
.rsum-sum-tech{
    font-size: 0.85rem;
}
.rsum-sum-tag {
	display: inline-block;
	padding:2px 5px;
	border: 1px dashed #444;
	margin:2px 5px 0 0;
	font-weight: 400;
    font-size: 0.90rem;
}
